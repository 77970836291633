export const projects = [
  {
    title: "Branding",
    description:
      "Involves the art of defining and communicating the essence with the deliberate construction of visual and conceptual framework to build a recognizable and influential image of your brand. ",
    src: "../assets/optimized/sales-optimized.webp",
    color: "#1C1E20",
    colorMob: "#FFFFFF",
    points: [
      "Stakeholder Interviews",
      "Employer Branding",
      "Market Research",
      "Launch Strategy",
      "Brand Architecture & Strategy",
      "Brand Tone & Voice",
      "Naming & Tagline",
      "Logo Design",
      "Signage and Environmental Design",
      "Packaging Design & Guidelines",
      "Brochure & Stationery Design",
      "Mascot Design",
      "Asset Libraries",
      "Brand & Identity Collaborations",
    ],
  },
  {
    title: "E-Commerce",
    description:
      "Brings together multiple operations including the establishment, surveillance and optimization of multiple online retail platforms, to enable seamless, secure and efficient shopping journey for modern consumers and maximizing commercial potential in the digital landscape.",
    src: "../assets/optimized/fullfillment-optimized.jpeg",
    webp: "https://firebasestorage.googleapis.com/v0/b/identitie-d1dc6.appspot.com/o/ecommerceService-optimized.webp?alt=media&token=3b0c952b-bc2e-4605-bffb-7079be02a287",
    color: "#1C1E20",
    points: [
      "Ecommerce platforms",
      "CRM/CMS Integration",
      "Product Listing",
      "Third-Party Integration",
     
      "SKU maintenance",
      "Order Tracking",
      "Email Marketing",
      "Influencer Marketing",
      "Abandoned Cart Verification",
      "Hosting and Maintenance",
      " Landing Pages",
      "Blogs",
      "Mobile & Responsive",
      "Website Design & Development",
      "App Design & Development",
      "YouTube Design & Development",
      "YouTube Advertising (PPC)",
      "Google"
    ],
    colorMob: "#FFFFFF",
  },
  {
    title: "Social Media Management ",
    description:
      "Expert orchestration of digital interactions, employing innovative tools and competent strategies to create creative, engaging and influential content that quivers with and expands your brand’s audience.",
    src: "../assets/optimized/marketing3-optimized.jpeg",
    webp: "https://firebasestorage.googleapis.com/v0/b/identitie-d1dc6.appspot.com/o/marketing3-optimized.webp?alt=media&token=766a994b-7dbb-4768-b12e-91c0dfff91dd",
    color: "#1C1E20",
    colorMob: "#FFFFFF",
    points: [
      "Style Guide",
      "Campaign Strategies",
      "Promotion Strategies",
      "Profile Makeover",
      "Visual Motion Ads",
      "Dynamic Graphics Posters",
      "Insightful Content",
      "Daily Posting",
      "Post Engagement",
      "Customer Support/Care",
    ],
  },
  {
    title: "Sales Empowerment ",
    description:
      "Deployment of strategic planning, advanced methodologies and tools to bolster your brand’s sales effectiveness, enhance performance, streamline processes, drive significant revenue growth and  larger customer acquisition.",
    src: "../assets/optimized/b2cService-optimized.jpeg",
    webp: "https://firebasestorage.googleapis.com/v0/b/identitie-d1dc6.appspot.com/o/b2cService-optimized.webp?alt=media&token=4e3fffbe-8cb2-4f7a-b750-37bba0cf84f6",
    color: "#1C1E20",
    colorMob: "#FFFFFF",
    points: [
      "Product Fact Sheets",
      "Messaging Playbook ",
      "Objection Handling",
      "Frequently Asked Questions",
      "Infographics",
      "Case Studies and Testimonials",
    ],
  },
  {
    title: "",
    description: "",
    src: "../assets/optimized/furniture-optimized.jpeg",
    webp: "https://firebasestorage.googleapis.com/v0/b/identitie-d1dc6.appspot.com/o/furniture-optimized.webp?alt=media&token=6cd8dd8d-f6ab-4c6f-906b-34dff2b2e469",
    color: "#161b22",
    colorMob: "#FFFFFF",
  },
];
export const products = [
	{
		title: "Snacks Food",
		description:
			"Explore authentic indian snacks,experience the taste of tradition",
		webp: "https://firebasestorage.googleapis.com/v0/b/identitie-d1dc6.appspot.com/o/chips-optimized.webp?alt=media&token=6769ba31-7a62-4a95-ba01-0672570a1f0d",
		image: "../assets/optimized/chips-optimized.webp",
	},
	{
		title: "Authentic Food",
		description:
			"Experience authentic flavours with our premium spices like black pepper ",
		webp: "https://firebasestorage.googleapis.com/v0/b/identitie-d1dc6.appspot.com/o/fruit-optimized.webp?alt=media&token=41fe4e86-c3ef-4525-9459-638699876c5f",
		image: "../assets/optimized/fruit-optimized.png",
	},
	{
		title: "Skin Care",
		description:
			"Discove skin with our skincare products and bring out your natural radiance",
		webp: "https://firebasestorage.googleapis.com/v0/b/identitie-d1dc6.appspot.com/o/Skincare-optimized.webp?alt=media&token=cbe5a99b-bd70-49fe-b9d8-f34aabf49618",
		image: "../assets/optimized/skincare-optimized.png",
	},
	{
		title: "Furniture",
		description:
			"Elavate your space with our curated collections of exquisite furnitures",
		webp: "https://firebasestorage.googleapis.com/v0/b/identitie-d1dc6.appspot.com/o/fur3-optimized.webp?alt=media&token=2c27ff34-5d1e-4db8-b69e-4acfbffd4249",
		image: "../assets/optimized/fur3-optimized.png",
	},
];

export const testimonials = [
	{
		name: "Sarah Johnson",
		title: "CEO of Johnson Enterprises",
		review:
			"I couldn't be more satisfied with the branding services provided by Identitie. Their team helped us redefine our brand identity, resulting in a significant increase in customer engagement and loyalty. Their professionalism and creativity are unmatched!",
	},
  {
		name: "David Martinez",
		title: "Marketing Director at Tech Solutions Inc",
		review:
			"Thanks to the marketing strategies implemented by Identitie, our online presence has grown exponentially. We've seen a 40% increase in website traffic and a substantial boost in our social media following. Their insights and execution are top-notch!",
	},
  {
		name: "Emily Roberts",
		title: "Sales Manager at Bright Future Corp.",
		review:
			"Identitie has been instrumental in transforming our sales approach. Their tailored solutions and expert guidance have helped us exceed our sales targets consistently. Their team is always available to support and innovate, making them an invaluable partner.",
	},
  {
		name: "Michael Brown",
		title: "Founder of Brown & Co",
		review:
			"Our collaboration with Identitie for our marketing campaign was a game-changer. Their strategic planning and creative execution resulted in a highly successful campaign that exceeded all our expectations. We look forward to working with them on future projects.",
	},
  {
		name: "Laura Wilson",
		title: "Creative Director at Innovate Designs",
		review:
			"The branding services from Identitie have given our company a fresh and modern look. Their ability to understand our vision and translate it into a compelling brand identity was truly impressive. We're thrilled with the results!",
	},
  {
		name: "Mark Taylor",
		title: "Head of Sales at Growth Dynamics",
		review:
			"Partnering with Identitie for our sales strategy was one of the best decisions we've made. Their data-driven approach and expert insights have led to a significant increase in our conversion rates. Highly recommend their services!",
	},
];
